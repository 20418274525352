html{
	box-sizing: border-box;
	& body{
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		min-height: 100%;
		min-height: 100vh;
		-webkit-tap-highlight-color:transparent;
		& .content {
			-ms-flex: 1 0 auto;
			flex: 1 0 auto;
		}
		& .footer {
			-ms-flex: 0 0 auto;
			flex: 0 0 auto;
		}
	}
}
*, *:before, *:after {
	box-sizing: inherit;
}
html, body  {
	min-height: 100%;
	width: 100%;
	background-color: @color-bg;
	color:@color-base;
}

body,  select, input, textarea  {
	font-size: @font-size-base;
	line-height: @font-line-height;
	font-weight: @font-weight-default;
	font-family: @font-default;
	-webkit-tap-highlight-color:transparent;
}
.screen(@min, @max, @ruleset) {
	@media (min-width: @min) and (max-width: (@max - 1)) {
		@ruleset();
	}
}

.screen(tablet-d, @ruleset) {
	@media (min-width: 2px) and (max-width: 767px) {
		@ruleset();
	}
}

.screen(tablet-u, @ruleset) {
	@media (min-width: 768px) and (max-width: 5000px) {
		@ruleset();
	}
}

.gradient(@dir,@color1,@color2){
	background:linear-gradient(@dir,@color1,@color2);
}
.flex(@row: row,@wrap: nowrap,@justify: flex-start,@align: stretch) {
	display: flex;
	flex-flow:@row @wrap;
	justify-content: @justify;
	align-items: @align;
}
.visible(d){
	.screen(2px, d,{
		display: none!important;
	});
}
.hid(d){
	.screen(d,700000px,{
		display: none!important;
	});
}

.sticky {
	position: fixed;
	z-index: 101;
}

.stop {
	position: relative;
	z-index: 101;
}
.container{
	margin-left: auto;
	margin-right: auto;
	.screen(2px, @s1300,{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		min-width: 320px;
	});
	.screen(@s1300,@s1600, {
		width: 1270px;
		padding-left: 15px;
		padding-right: 15px;
	});
	.screen(@s1600,70000px, {
		width: 1270px;
		padding-left: 15px;
		padding-right: 15px;
	});
}
.label{
	display: inline-block;
	padding:0px 8px;
	border-radius:15px;
	margin: 2px;
	font-size:10px;
	text-transform: uppercase;
	color:white;
	&-new{
		background-color: #56cc00;
	}
	&-sale{
		background-color: #f6c234;
	}
	&-hit{
		background-color: #e73c3c;
	}
	&-wrapper{
		position: absolute;
		top:0;
		right:0;
		text-align:right;
	}
}

.scr-no{
	overflow: hidden;
}
.ovf-no{
	overflow: initial!important;
}
a{
	text-decoration: none;
	outline: none;
	display: inline-block;
	&:hover{
		text-decoration: none;
	}
	&:focus{
		text-decoration: none;
		outline: none;
	}
}
.reset-around{
	margin:0;
	padding:0;
}
.modal{
	&-mask{
		position: fixed;
		z-index: 9998;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
		display: table;
		transition: opacity .3s ease;
	}
	&-wrapper{
		display: table-cell;
		vertical-align: middle;
	}
	&-container{
		width: 100%;
		max-width:460px;
		margin: 0px auto;
		padding: 0;
		background-color: #fff;
		box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
		transition: all .3s ease;
		position: relative;
		border-radius:25px;
	}
	&-body{
		padding:40px 60px;
	}
	&-close{
		background-color: transparent;
		position: absolute;
		top:10px;
		right:10px;
		padding:10px;
		cursor: pointer;
		&:hover{
			svg{
				fill:black;
			}
		}
		svg{
			fill:#8f8f8f;
			display: block;
		}
	}
	&-callback-1{
		.modal{
			&-container{
				max-width:420px;
				width:~'calc(100% -	40px)';
				text-align:left;
				.title{
					font-size:19px;
					font-weight:700;
					text-align: center;
				}
				.text{
					white-space: normal;
					font-family: @font-default;
					font-size:15px;
					line-height:22px;
					text-align: center;
					margin:0 0 20px 0;
				}
			}
			&-body{
				padding:30px;
			}
		}
	}
	&-log{
		cursor: default;
		&_make{
			.title{
				font-size:19px;
				font-weight:700;
				text-align: center;
			}
			.text{
				white-space: normal;
				font-family: @font-default;
				font-size:15px;
				line-height:22px;
				text-align: center;
				margin:0 0 20px 0;
			}
		}
		.modal-container{
			max-width:440px;
			width:~'calc(100% - 40px)';
		}
		.modal-body{
			max-height: 90vh;
			overflow: auto;
		}
		&_remember{
			border:0;
			background: none;
			outline:none;
			color:@color-link;
			border-bottom:1px solid @color-link;
			.reset-around;
			line-height:1;
			margin-left:15px;
			.screen(2px,450px,{
				margin-top:25px;
				margin-left:0;
			});
			&:hover{
				border-color:transparent;
			}
		}
		&_social{
			width:~'calc(100% + 70px)';
			margin-left:-35px;
			margin-bottom:-15px;
			border-top:1px solid #e5e5e5;
			.flex(row,wrap,space-between,center);
			margin-top:40px;
			position: relative;
			//top:15px;
			padding:20px 25px 0 35px;
			p{
				color:#777777;
				margin-right:10px;
			}
		}
		&_up{
			.flex(row,nowrap,flex-start,flex-start);
			button{
				border:0;
				background:none;
				font-size:19px;
				font-weight:700;
				background-color: #e8eef0;
				width:65%;
				text-align: center;
				padding: 25px 15px;
				&:nth-of-type(1){
					width:45%;
				}
				&.current{
					background-color: #fff;
				}
			}
		}
		&_cont{
			padding:35px;
		}
	}
	&-base{
		.title{
			text-align: center;
			font-family: @font-lora;
			font-size:22px;
			font-weight:400;
			line-height: 1.1;
			margin-bottom:25px;
		}
	}

}
.modal-enter {
	opacity: 0;
}
.modal-leave-active {
	opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
	.screen(@s750,90000px,{
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	});
	.screen(2px,@s750,{
		.translateX(100%);
		transition:all 0.3s;
	});

}
.text-center{
	text-align: center;
}
.error_title{
	position: relative;
	bottom:10px;
	color:@color-pink;
	text-align: center;
}
.input-wrapper{
	margin-bottom:20px;
	&_button{

	}
	input{
		border-style: solid;
		border-width: 1px;
		border-color: #c7c7c7;
		background-color: rgb(255, 255, 255);
		width: 100%;
		height: 50px;
		outline:none;
		padding:0 20px;
		border-radius:25px;

		&.error{
			border-color:@color-pink;
		}
	}
	span{
		&.error{
			color:@color-pink;
			font-size:13px;
			padding:0 25px;
			display: block;
		}
	}
	label{
		display: block;
		margin-bottom: 5px;
		color:#4c4c4c;
		&.req:after{
			content:'*';
			color:@color-pink;
			margin-left:5px;
		}
	}
}
label{
	&.req:after{
		content:'*';
		color:@color-red;
		margin-left:5px;
	}
}
*[v-cloak]{
	display: none;
}
.sinput{
	border: 1px solid rgb(202, 202, 202);
	background-color: rgb(255, 255, 255);
	box-shadow: inset 0px 1px 6px 0px rgba(215, 215, 215, 0.4);
	height: 48px;
	outline: none;
	padding:0 15px;
}
button{
	&.pink{
		border-radius:25px;
		background-color: @color-pink;
		color:white;
		background-color: rgb(231, 13, 133);
		box-shadow: 0px 5px 15px 0px rgba(183, 17, 115, 0.3);
		font-size:17px;
		padding:14px 40px;
		border:1px solid @color-pink;
		outline:none;
		height: 50px;
		&:hover{
			background-color: #f22f9a;
		}
		&:active{
			background-color: #e70d85;
		}
		&.incart{
			background-color:white;
			color:@color-pink;
			span{
				&:before{
					content:'';
					.size(30px,19px);
					display: inline-block;
					background-image: url(../img/checked.svg);
					background-repeat:no-repeat;
					background-size:contain;
					background-position: center center;
					vertical-align: middle;
					position: relative;
					bottom:2px;
				}
			}
		}
	}
}
.pink{
	border-radius:25px;
	background-color: @color-pink;
	color:white;
	background-color: rgb(231, 13, 133);
	box-shadow: 0px 5px 15px 0px rgba(183, 17, 115, 0.3);
	font-size:17px;
	padding:14px 40px;
	border:1px solid @color-pink;
	outline:none;
	height: 50px;
	&:hover{
		background-color: #f22f9a;
	}
	&:active{
		background-color: #e70d85;
	}
}
.fade-enter-active, .fade-leave-active {
	transition: opacity .3s
}
.fade-enter, .fade-leave-to /* .fade-leave-active для <2.1.8 */ {
	opacity: 0;
}

.section-in {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}
.text-center{
	text-align:center;
}
.editor{
	font-size: 15px;
	color:@color-base;
	line-height: 22px;
	& a{
		color:@color-link;
		border-bottom:1px solid @color-link;
		&:hover{
			border-color:transparent;
		}
	}
	& p{
		margin:0 0 0.7em 0;
	}
	& ul{
		.reset-around;
		padding-left:40px;
		margin-top:15px;
		margin-bottom: 15px;
	}

}
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes popIn {
	from {
		.scale(0.5);
	}
	to {
		.scale(1);
	}
}
@keyframes popIn {
	from {
		.scale(0.5);
	}
	to {
		.scale(1);
	}
}




